import React, { useEffect, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { uid } from 'uid';
import { useAuth } from '../../component/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { endSession } from '../../redux/sessionReducer';

export default function MeetingPrescription()
{
    const queryParams = new URLSearchParams( window.location.search );

    const [ sessionTextArea, setSessionTextArea ] = useState( "" );
    const [ therapistName, setTherapistName ] = useState( "" );
    const [ therapistId, setTherapistId ] = useState( "" );
    const [ patientProgress, setPatientProgress ] = useState( '' );
    const [ selectedImage, setSelectedImage ] = useState( [] );
    const [ sessionDone, setSessionDone ] = useState( '' );
    const [ timeArray, setTimeArray ] = useState( [] );
    const [ patientID, setPatientID ] = useState( "" );
    const [ visible, setVisible ] = useState( false );
    const [ roomId, setRoomId ] = useState( "" );
    const [ timeFinal, setTimeFinal ] = useState( 0 );
    const [ selected, setSelected ] = useState( '' );
    const [ milliSec, setMilliSec ] = useState( 0 );
    const [ patient, setPatient ] = useState( "" );
    const [ watch, setWatch ] = useState( false );
    const [ role, setRole ] = useState( "" );
    const [ time, setTime ] = useState( 0 );
    const [ date, setDate ] = useState( "" );
    const [ flag, setFlag ] = useState( false );
    // const [ roomm, setRoom ] = useState( "" );
    const [ recording, setRecording ] = useState( false );

    console.log( recording );

    const dispatch = useDispatch();

    const navigate = useNavigate();

    let sessions = '';
    sessions = useSelector( state => state.session.sessions );
    const [ sessionDataRedux, setSessionData ] = useState( sessions );

    useEffect( () =>
    {
        setSessionData( sessions );
    }, [ sessions ] );


    useEffect( () =>
    {
        setPatientID( queryParams.get( "pid" ) );
        setRole( queryParams.get( "role" ) );
        setRoomId( queryParams.get( "roomId" ) );
        setRecording( queryParams.get( 'recording' ) );
    }, [ queryParams ] );

    const imgTypes = [ 'image/jpeg', 'video/mp4' ];
    const [ disable, setDisable ] = useState( false );
    const [ type, setType ] = useState( true );
    const imageref = useRef( null );
    // const isWeekday = (date) => {
    //   const day = date.getDay();
    //   return day !== 0;
    // };

    // let images = []

    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;

    const patientDetails = async () =>
    {
        try
        {
            if ( patientID?.length > 0 )
            {
                const res = await axios.get( `${server}/api/medi/patient-dash/details/${patientID}` );
                if ( res.status === 200 )
                {
                    setPatient( res.data.patient );
                    setTherapistName( JSON.parse( res.data.patient?.therapistArray )[ 0 ]?.label );
                    setTherapistId( JSON.parse( res.data.patient?.therapistArray )[ 0 ]?.value );
                    setSessionDone( res.data.patient.sessionDone );
                }
            }
        } catch ( error )
        {
            console.log( error );
        }
    };

    useEffect( () =>
    {
        patientDetails();
    }, [ patientID ] );

    const handleSessionNote = async () =>
    {
        try
        {
            const sessionCount = sessionDone;
            const sessionIncr = Number( sessionCount ) + 1;

            // let oldTime = Object.values( sessions ).find( item => item.id === patientID )[ 'sessionTime' ];
            // let newTime = moment().format();
            // let sessionTime = moment.duration( moment( newTime ).diff( oldTime ) );
            // setMilliSec( sessionTime._milliseconds );
            setMilliSec( "0" );

            const form = new FormData();
            let arrImages = [];


            const sessionData = {
                'id': uid( 16 ), 'dummy': 'key', 'milliSec': "",
                'time': timeFinal, 'sessionDone': sessionIncr, 'sessionTextArea': sessionTextArea, 'patientProgress': patientProgress,
                'date': moment().format(), 'materialDate': date, "roomId": roomId, "recording": recording,
                'therapist': therapistName, "therapistID": therapistId
            };

            form.append( 'sessionData', JSON.stringify( sessionData ) );
            form.append( 'images', JSON.stringify( arrImages ) );

            const res = await axios.post( `${server}/api/medi/patient/session-done/${patientID}?roomId=${roomId}`, form );
            console.log( res );
            if ( res.data.success )
            {
                toast.success( res.data.message );
                setVisible( false );
                setTime( '' );
                setSessionTextArea( '' );
                setMilliSec( 0 );
                setSessionDone( "" );
                navigate( "/meetingOver" );
                dispatch( endSession( {
                    id: patientID
                } ) );
            }
        } catch ( error )
        {
            toast.error( "Something went wrong while starting session" );
        }
    };
    /* image handler */
    const imagehandle = ( e ) =>
    {
        let flag = true;
        if ( e.target.files && e.target.files.length > 0 )
        {
            // const files = e.target.files
            // Object.values(files).map((item) => (
            //   images.push(item)
            // ))
            // console.log(e.target.files)
            Object.values( e.target.files ).map( item =>
            {
                if ( item.size > 5242880 )
                {
                    return (
                        flag = false,
                        toast.error( "file size must be less than 5 mb" )
                    );
                }
                if ( !imgTypes.includes( item.type ) )
                {
                    return (
                        flag = false,
                        imageref.current.value = "",
                        imageref.current.type = "text",
                        imageref.current.type = "file",
                        toast.error( "file type must be of jpeg or mp4 only" )
                    );
                }
            } );
            if ( flag )
            {
                setSelectedImage( e.target.files );
            }
        }
    };


    const handleSubmit = () =>
    {
        if ( sessionTextArea?.length === 0 )
        {
            toast.error( "Please write in session note" );
        }
        if ( patientProgress?.length === 0 )
        {
            toast.error( "Please Write in Patient Progress" );
        }
        if ( sessionTextArea?.length > 0 && patientProgress?.length > 0 )
        {

            handleSessionNote();
            setDisable( true );
        }
    };

    // console.log( role );

    return (
        <div className='row'>
            <div className='col-3 col-xl-3'>

            </div>
            <div className='col-xl-6 mt-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <label><b>Clinical Note <sup style={{ color: 'red' }}>*</sup></b></label>
                            <textarea
                                className='form-control'
                                value={sessionTextArea}
                                onChange={( e ) => setSessionTextArea( e.target.value )}
                                placeholder='Enter Session ending notes here'
                                cols={10}
                                rows={10}
                            />
                        </div>
                    </div>
                    <div className='row mt-1'>
                        <div className='col-12'>
                            <label><b>Patient Progress Notes <sup style={{ color: 'red' }}>*</sup></b></label>
                            <textarea
                                className='form-control'
                                placeholder='Enter patients Progress Details here'
                                onChange={( e ) => setPatientProgress( e.target.value )}
                                cols={10}
                                rows={10}
                            />
                        </div>
                    </div>
                    {/* <div className='row mt-1'>
                        <div className='col-12'>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                        'DesktopDateTimePicker'
                                    ]}
                                >
                                    <DemoItem label="Next Session Date">
                                        <DesktopDateTimePicker
                                            defaultValue={dayjs( new Date().getTime() + ( 24 * 60 * 60 * 1000 ) )}
                                            minDate={dayjs( new Date() )}
                                            onChange={value => setDate( value )}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className='mt-2'>
                            <label><b>Upload files : </b></label>
                            <input
                                type='file'
                                className='form-control'
                                multiple
                                onChange={( e ) =>
                                {
                                    setType( true );
                                    imagehandle( e );
                                }}
                                ref={imageref}
                            />
                        </div>
                    </div> */}
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <button
                                className={( () =>
                                {
                                    if ( !disable )
                                    {
                                        return ( 'btn btn-success form-control btn-sm' );
                                    } else
                                    {
                                        return ( 'btn btn-success form-control btn-sm disabled' );
                                    }
                                } )()}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}
