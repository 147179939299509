import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import "./confo.css";
import { Modal } from 'antd';
import CallMsg from './CallMsg';

let EnxRtc = window.EnxRtc;

export default function VideoCall()
{
    const queryParams = new URLSearchParams( window.location.search );

    const [ therapistName, setTherapistName ] = useState( "" );
    const [ patientName, setPatientName ] = useState( "" );
    const [ patientID, setPatientID ] = useState( "" );
    const [ role, setRole ] = useState( "" );
    const [ patient, setPatient ] = useState( "" );
    const [ flag, setFlag ] = useState( false );
    const [ errorToken, setErrorToken ] = useState( '' );
    const [ token, setToken ] = useState( "" );
    const [ participants, setParticipants ] = useState( [] );
    const { roomId } = useParams();
    const [ activTalker, setActivTalker ] = useState( "" );
    const [ visible, setVisible ] = useState( false );
    const [ recording, setRecording ] = useState( false );

    console.log( recording );
    const server = `${process.env.REACT_APP_AWS_LAMBDA}`;
    let room = null;
    let localStream = null;
    const [ localStream2, setLocalStream ] = useState( null );

    let msg = ( role === "moderator" ) ? "Please stay, Patient will join in shortly...." : "Please stay, Therapist will join in shortly....";

    const options = {
        id: 'vcx_1001',
        player: {
            frameFitMode: 'bestFit',
            height: '100%',
            width: '100%',
            volume: 0,
            backgroundImg: '/img/player-bg.gif',
        },
        activeTalker: true,
        toolbar: {
            displayMode: 'auto',
        },
    };

    let goFlag = 0;

    useEffect( () =>
    {
        setPatientID( queryParams.get( "pid" ) );
        setRole( queryParams.get( "role" ) );
    }, [ queryParams ] );

    useEffect( () =>
    {
        const fetchPatientDetails = async () =>
        {
            try
            {
                if ( patientID?.length > 0 )
                {
                    const res = await axios.get( `${server}/api/medi/patient-dash/details/${patientID}` );
                    if ( res.status === 200 )
                    {
                        const patientData = res.data.patient;
                        setPatient( patientData );
                        setPatientName( patientData.name );
                        if ( patientData?.therapistArray )
                        {
                            setTherapistName( JSON.parse( patientData.therapistArray )[ 0 ]?.label );
                        } else
                        {
                            setTherapistName( patientData?.trial[ 0 ][ 0 ].therapistName );
                        }
                    }
                }
            } catch ( error )
            {
                console.error( 'Error fetching patient details:', error );
            }
        };

        fetchPatientDetails();
    }, [ patientID ] );

    var listActive = [];


    useEffect( () =>
    {
        const createToken = async ( data, callback ) =>
        {
            try
            {
                if ( roomId && patientName && therapistName && !token )
                {
                    const res = await axios.post( `${server}/api/medi/vcall/create-token`, {
                        roomId,
                        role: ( () =>
                        {
                            if ( role === "participant" )
                            {
                                return role;
                            }
                            else if ( role === 'moderator' )
                            {
                                return role;
                            }
                            else return "participant";
                        } )(),
                        name: ( () =>
                        {
                            if ( role === "participant" ) return patientName;
                            else if ( role === "moderator" ) return therapistName;
                            else return role;
                        } )(),
                        user_ref: ( () =>
                        {
                            if ( role === "participant" ) return patientName;
                            else if ( role === "moderator" ) return therapistName;
                            else return role;
                        } )(),
                    } );
                    if ( res.status === 200 && res.data?.res?.token )
                    {
                        const newToken = res.data.res.token;
                        setToken( newToken );
                        callback( newToken );
                    } else if ( res?.data?.error?.error )
                    {
                        setFlag( true );
                        console.log( res?.data?.error?.error );
                        const errorMsg = res.data.error.error;
                        setErrorToken( errorMsg );
                        toast.error( errorMsg );
                        // callback( errorMsg );
                    }
                }
            } catch ( error )
            {
                console.error( 'Error creating token:', error );
                setErrorToken( error );
                toast.error( 'An error occurred while creating the token.' );
            }
        };

        const setupVideoCall = () =>
        {
            const tokenData = {
                name: role === 'participant' ? patientName : therapistName,
                role,
                roomId,
                user_ref: role === 'participant' ? patientName : therapistName,
            };

            createToken( tokenData, ( generatedToken ) =>
            {
                if ( EnxRtc && generatedToken )
                {
                    EnxRtc.joinRoom( generatedToken, {
                        audio: true,
                        video: true,
                        data: true,
                        // videoSize: [ 320, 180, 640, 480 ],
                        options,
                        // maxVideoLayers: 0,
                        attributes: {
                            name: role === 'moderator' ? therapistName : patientName,
                        },
                    }, ( success, error ) =>
                    {
                        if ( error )
                        {
                            if ( error?.message )
                            {
                                setFlag( true );
                                setErrorToken( error?.message );
                            }
                            console.error( 'Error joining room:', error );
                            return;
                        }

                        room = success.room;
                        localStream = success.localStream;
                        setLocalStream( success.localStream );

                        if ( localStream )
                        {
                            setLiveStream( localStream, role === 'moderator' ? therapistName : patientName, 0 );
                        }

                        console.log( success.streams, "success.streams" );

                        success.streams.forEach( ( stream ) =>
                        {
                            if ( stream )
                            {
                                room.subscribe( stream );
                            } else
                            {
                                console.warn( 'Stream is undefined:', stream );
                            }
                        } );

                        updateUsersList( room );

                        room.addEventListener( 'active-talkers-updated', ( event ) =>
                        {
                            console.log( "active-talkers-updated" );
                            const activeTalkers = event.message.activeList || [];
                            console.log( activeTalkers, "koooo" );

                            if ( activeTalkers.length === 0 )
                            {
                                setVisible( true );

                            }
                            if ( activeTalkers.length > 0 )
                            {
                                setVisible( false );
                            }

                            document.querySelectorAll( '.vcx_stream' ).forEach( ( item ) =>
                                item.classList.remove( 'border-b-active' )
                            );

                            const newParticipants = activeTalkers.map( talker => ( {
                                id: talker.clientId,
                                name: talker.name,
                                stream: room.remoteStreams.get( talker.streamId )
                            } ) );

                            activeTalkers.map( talker => setLiveStream( room.remoteStreams.get( talker.streamId ), "", talker.clientId ) );

                            setActivTalker( activeTalkers );

                            setParticipants( newParticipants );

                        } );

                        room.addEventListener( 'stream-subscribed', ( streamEvent ) =>
                        {
                            console.log( "stream-subscribed" );
                            const stream = streamEvent.data?.stream || streamEvent.stream;

                            // if ( stream && stream.getID() !== 101 && stream.getID() !== 102 )
                            // {
                            //     console.log( stream.getID(), "hereee" );
                            //     setLiveStream( stream, stream.getAttributes().name, stream.getID() );

                            // } else
                            // {
                            //     console.warn( 'Stream subscribed event received with undefined stream:', streamEvent );
                            // }
                        } );

                        room.addEventListener( 'user-connected', () =>
                        {
                            updateUsersList( room );
                        } );

                        window.addEventListener( "beforeunload", ( event ) =>
                        {
                            console.log( "beforeunload" );
                            console.log( "Tab is about to be closed." );
                            // Your code to handle the event, such as disconnecting from a service or saving data
                            room.disconnect();

                            // Optionally, prevent the default unload behavior (not always needed in modern browsers)
                            // event.preventDefault();
                            // Chrome requires setting returnValue for custom messages, though most browsers ignore custom messages now
                            // event.returnValue = 'hiiii';
                        } );

                        window.addEventListener( "popstate", () =>
                        {
                            console.log( 'popstate' );
                            room.disconnect();
                        } );

                        room.addEventListener( 'user-disconnected', ( event ) =>
                        {
                            const user = event;
                            console.log( event, "eventwa" );
                            if ( user && user.clientId )
                            {
                                setParticipants( prevParticipants =>
                                {
                                    const newParticipants = prevParticipants.filter( p => p.id !== user.clientId );
                                    const streamDiv = document.getElementById( `remotStream_${user.clientId}` );
                                    if ( streamDiv )
                                    {
                                        streamDiv.remove();
                                    }
                                    return newParticipants;
                                } );
                                updateUsersList( room );
                            } else
                            {
                                console.warn( 'User object or clientId is undefined in user-disconnected event' );
                            }
                        } );

                        document.querySelector( "#disconnect_call" ).addEventListener( "click", async function ()
                        {
                            if ( role === "moderator" )
                            {
                                room?.dropUser( [], function ( res )
                                {
                                    // console.log( res );
                                    if ( res.result == 0 )
                                    {
                                        room.disconnect();
                                    }
                                } );
                            } else
                            {
                                room.disconnect();
                            }
                        } );

                        console.log( room?.roomMeta?.auto_recording );
                        room.addEventListener( "room-disconnected", function ( streamEvent )
                        {
                            if ( role === 'moderator' )
                            {
                                setRecording( room?.roomMeta?.auto_recording );
                                if ( goFlag === 0 )
                                {
                                    window.location.href = `/meetingPrescription?pid=${patientID}&role=${role}&roomId=${roomId}&recording=${room?.roomMeta?.auto_recording}`;
                                } else
                                {
                                    window.location.reload();
                                }
                            }
                            else
                            {
                                window.location.href = "/meetingOver";
                            }
                        } );

                    } );
                } else
                {
                    console.error( 'EnxRtc is not defined or token is missing.' );
                }
            } );
        };

        if ( EnxRtc ) setupVideoCall();
    }, [ patientName, token ] );


    const setLiveStream = ( stream, remote_name, index ) =>
    {
        const container = document.querySelector( '.video_container_div' );
        if ( !document.getElementById( `remotStream_${index}` ) )
        {
            const streamDiv = document.createElement( 'div' );
            streamDiv.setAttribute( 'id', `remotStream_${index}` );
            streamDiv.setAttribute( 'class', `live_stream_div relative ${role === 'moderator' ? 'moderator' : 'participant'}` );
            container.appendChild( streamDiv );
            console.log( stream, "hiiii" );
            stream.show( `remotStream_${index}`, options );
        } else
        {
            console.log( `Stream with ID remotStream_${index} already exists.` );
        }

    };

    const updateUsersList = ( room ) =>
    {
        const usersListSidebar = document.querySelector( '#users-list-sidebar' );
        if ( !usersListSidebar )
        {
            console.warn( 'User list sidebar element not found' );
            return;
        }

        if ( !room || !room.userList )
        {
            console.warn( 'Room or user list is undefined' );
            return;
        }

        let list = '';
        room.userList.forEach( ( user, clientId ) =>
        {
            // console.log( user, clientId, "ioooooooo" );
            if ( clientId !== room.clientId )
            {
                list += `<li class="list-group-item" id="user_${clientId}">${user.name}</li>`;
            }
        } );

        usersListSidebar.innerHTML = list;
    };

    const audioMute = () =>
    {
        const elem = document.querySelector( '.icon-confo-mute img' );
        const onImgPath = '/img/mike.png';
        const offImgPath = '/img/mute-mike.png';

        if ( elem && elem.src.includes( 'mute-mike.png' ) )
        {
            localStream2.unmuteAudio( () =>
            {
                elem.src = onImgPath;
                elem.title = 'mute audio';
            } );
        } else
        {
            localStream2.muteAudio( () =>
            {
                elem.src = offImgPath;
                elem.title = 'unmute audio';
            } );
        }
    };

    const videoMute = () =>
    {
        const elem = document.querySelector( '.icon-confo-video-mute img' );
        const onImgPath = '/img/video.png';
        const offImgPath = '/img/mute-video.png';

        if ( elem && elem.src.includes( 'mute-video.png' ) )
        {
            localStream2.unmuteVideo( () =>
            {
                elem.src = onImgPath;
                elem.title = 'mute video';
            } );
        } else
        {
            localStream2.muteVideo( () =>
            {
                elem.src = offImgPath;
                elem.title = 'unmute video';
            } );
        }
    };

    console.log( activTalker.length );

    return (
        <>
            {!flag ? ( <div className="relative h-screen flex flex-col bg-gray-900 text-white">
                <div className="flex-grow flex flex-col md:flex-row overflow-hidden">
                    <div className={`w-full md:w-5/6 video_container_div justify-center items-center gap-4 p-4 grid overflow-hidden ${( () =>
                    {
                        if ( activTalker.length < 2 )
                        {
                            return ( 'grid-cols-1 md:grid-cols-2' );
                        }
                        else if ( activTalker.length === 3 )
                        {
                            return ( `grid-cols-2 md:grid-cols-2` );
                        }
                        else if ( ( activTalker.length >= 2 ) )
                        {
                            return ( `grid-cols-2 md:grid-cols-3` );
                        }
                    } )()}`}>
                        {/* {participants.map( ( participant, index ) => (
                            <div key={participant.id} id={`remotStream_${participant.id}`} className="live_stream_div participant absolute overflow-hidden bg-black rounded-lg">
                                <span className="bottom-2 left-2 text-sm font-bold bg-black/50 px-2 py-1 rounded">{participant.name}</span>
                            </div>
                        ) )} */}
                    </div>
                    <div className="hidden md:block w-full md:w-1/6 p-4">
                        <h2 className="text-lg font-semibold mb-4">Participants</h2>
                        <ul id="users-list-sidebar" className="list-none space-y-2 text-sm"></ul>
                    </div>
                </div>
                <div className="flex items-center justify-center p-4 bg-gray-800 gap-2">
                    <button onClick={audioMute} className="mx-2 icon-confo-mute">
                        <img src="/img/mike.png" alt="Mute Audio" className="w-10 h-10" />
                    </button>
                    <button onClick={videoMute} className="mx-2 icon-confo-video-mute">
                        <img src="/img/video.png" alt="Mute Video" className="w-10 h-10" />
                    </button>
                    <div className="video-mute-icon end-call bg-danger" title="End Call" id="disconnect_call" style={{ width: "35px", height: "35px" }}>
                        <button className='btn mb-3 flex justify-center' style={{ width: "100%", height: "100%" }}>
                            <i className="fas fa-phone fa-fw CBicon font"  ><FontAwesomeIcon icon={faPhone} /></i>
                        </button>
                    </div>
                </div>
            </div> ) : ( <div className='h-screen flex justify-center items-center'> {errorToken}</div> )}
            <Modal
                open={visible}
                footer={null}
                closable={false}
                onCancel={() =>
                {
                    setVisible( false );
                }}
            >
                <CallMsg msg={msg} />
            </Modal>
        </>
    );
}
