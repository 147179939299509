import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from "antd";
import ImageModal from './ImageModal';


export default function ViewSessionDetails( { sessionList, setDocumentID, handleDocumentClick, patientCondition, handleVideo, setRoomId, setErrFlag } )
{
    const [ visible, setVisible ] = useState( false );
    const [ image, setImage ] = useState( '' );

    const handleDownload = async ( roomId ) =>
    {
        try
        {
            setErrFlag( true );
            setRoomId( roomId );
            handleVideo( roomId );
        } catch ( error )
        {
            console.log( error );
        }
    };

    // console.log(image)
    return (

        <div className='overflow-auto' style={{ maxHeight: '500px' }}>
            <div className='row'>
                <div className='col-12 text-center'>
                    <div className='card'>
                        <div className='card-header bg-success text-light'>
                            Patient's Initial Condition
                        </div>
                        <div className='card-body'>
                            {patientCondition}
                        </div>
                    </div>
                </div>
            </div>
            {Object.values( sessionList ).map( ( item, i ) => (
                <div key={i} className='card m-2'>
                    <div className="card-header bg-secondary"
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div style={{ color: 'white' }}>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12 col-xl-5'>
                                            {"Session ID : " + item.id}
                                        </div>
                                        <div className='col-12 col-xl-4'>
                                            {JSON.stringify( new Date( item?.date ).toLocaleDateString() )}
                                            {/* {new Date( item?.date ).toDateString()} */}
                                        </div>

                                        {/* {item.images
                                            ? (<div className='col-3'>
                                                <button
                                                    className='btn btn-info float-end'
                                                    onClick={() => {
                                                        setDocumentID(item.id);
                                                        handleDocumentClick();
                                                    }}
                                                >
                                                    View Documents
                                                </button>
                                            </div>)
                                            : (<></>)} */}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12 col-xl-8'>
                                        <div className='row'>
                                            <div className='col-8'>
                                                <div>
                                                    <b>Duration : </b>
                                                    {item.time}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-8'>
                                                <div>
                                                    {item.materialDate?.length > 0 ? ( <div><b>Next Session Date : </b>{new Date( item?.materialDate ).toLocaleDateString()
                                                        // + ' / ' + new Date( item.materialDate ).toLocaleTimeString()
                                                    }</div> ) : ( <></> )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-8'>
                                                <div>
                                                    {item.therapist !== undefined ? ( <div><b>Therapist Name : </b>{( item.therapist )}</div> ) : ( <></> )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='row'>
                                            <div className='col-8'>
                                                {item?.date !== undefined
                                                    ? ( <div className='col-8'>
                                                        <b>Date : </b>{new Date( item?.date ).toLocaleDateString()}
                                                    </div> )
                                                    : ( <div className='col-8'>

                                                    </div> )}
                                            </div>
                                        </div> */}

                                    </div>
                                    {item?.images?.length > 0 ? ( <div className='col-12 col-xl-4'>
                                        <div className='row border'>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    Uploaded Documents
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>

                                                {Object.values( item?.images ).map( ( img, i ) => (
                                                    <Link
                                                        key={i}
                                                        onClick={() =>
                                                        {
                                                            // console.log( img );
                                                            setVisible( true );
                                                            setImage( img );
                                                        }}
                                                    // className='btn btn-outline-primary'
                                                    >
                                                        <button className='btn btn-warning mt-1'>
                                                            {"Doc_" + i}
                                                        </button>
                                                        <br></br>
                                                    </Link>
                                                    // <img src={`${process.env.REACT_APP_SESSION_IMAGE_PATH}` + img.path} className="card-img-top d-block w-100" alt="session-image" style={{ maxHeight: '1000px' }}></img>
                                                ) )}

                                            </div>
                                        </div>
                                    </div> ) : ( <></> )}
                                </div>
                            </div>
                        </div>


                        <hr />
                        <div className=''>

                            <b>Clinical Note : </b>
                            {item.sessionTextArea}

                        </div>
                        <hr />
                        <div>

                            <b>Patient's Progress : </b>
                            {item.patientProgress}

                        </div>
                        {/* {JSON.stringify( item.roomId )} */}
                        {item.recording === "true" ? (
                            <>
                                <hr />
                                <div className='mt-2'>
                                    <button className='btn btn-warning' onClick={() => handleDownload( item.roomId )}>Download Video</button>
                                </div>
                            </>
                        ) : ( <></> )}

                    </div>
                </div>
            ) )}
            <Modal
                open={visible}
                onCancel={() =>
                {
                    setVisible( false );
                }}
                footer={null}
            >
                <ImageModal
                    image={image}
                />
            </Modal>
        </div>

    );
}
