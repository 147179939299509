import React from 'react';
import ReactMarkdown from 'react-markdown';

export default function Releaselog()
{

    const md = `
        # Code Name: ub.deltoid.client.1.9.5
        # Code Name: ub.deltoid.server.1.6.4

        ---
        ### NEW :
        ---
        ** Video Recording Toggle ** : A toggle button has been added when scheduling a call, allowing users to enable or disable video recording for the session.

        ** Session Video Download **: Therapists can now download recorded session videos by clicking the "View Session" button and selecting the download option.

        `;
    return (
        <div className='h-screen flex justify-center items-center '>
            <ReactMarkdown children={md}>

            </ReactMarkdown>
        </div>
    );
}
